import React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import LoginLogout from "./LoginLogout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
const UPLAND_CONTACT_US = 'https://www.glendaleheights.org/contactus.asp';
function Footer({ className }) {
    return (<footer className={cx("footer", className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <a className="footer-link" target="_blank" href={UPLAND_CONTACT_US}>
            <Trans>Contact Us</Trans>
          </a>
          <a className="footer-link" target="_blank" role="img" href="https://www.facebook.com/voghnews" aria-label="facebook-link">
            <FontAwesomeIcon icon={faFacebookF}/>
          </a>
          <a className="footer-link" target="_blank" role="img" href="https://twitter.com/voghnews" aria-label="twitter-link">
            <FontAwesomeIcon icon={faTwitter}/>
          </a>
          <a className="footer-link" target="_blank" role="img" href="https://www.youtube.com/user/GlendaleHtsTV" aria-label="instagram-link">
            <FontAwesomeIcon icon={faYoutube}/>
          </a>
          <LoginLogout className="footer-link"/>
        </nav>
      </div>
    </footer>);
}
export default Footer;
